import assert from "assert"
import Axios from "axios"
import * as Sentry from "@sentry/browser"
import { FormikValues } from "formik"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import {
  ColumnFilter,
  ColumnFiltersState,
  SortingState,
} from "@tanstack/react-table"

import { useSessionStore } from "../stores"
import { assertAccountId } from "../utils"
import {
  buildSignedURL,
  getProtected,
  postProtected,
  putProtected,
  deleteProtected,
  wrappedAxios,
  ncapxPlatformURL,
} from "./auth"
import { UseAccountDocumentActionURLTypes } from "../hooks/react-query/utils/useAccountDocumentActionURL"
import { EligibleLeadFilterFunctionsTypes } from "../context/PartnersContext"
import { URL_PARAM_SUFFIX } from "../sections/Partners/PartnersTable/constants"
import { EligibilityQuizCategoryType } from "@/types/constants"
import { AsanaTaskCreationParams } from "@/types/asana"

type QueryParams = Record<string, string>
type ProtectedResponse<T> = Promise<T>

const e = encodeURIComponent
const REPORT_ALL_API_VERSION = 5
const profileEndpoint = "profile/"
const errorTestEndpoint = "error-test/"

export const getProfile = async (): ProtectedResponse<any> => {
  const res = await getProtected(profileEndpoint)
  return res.data
}

export const updateProfile = (values: FormikValues): ProtectedResponse<any> => {
  return putProtected(profileEndpoint, values)
}

export const resendEmailVerification = async (): ProtectedResponse<any> => {
  return await postProtected("profile/resend-email-verification")
}

export const verifyEmail = async (
  values: FormikValues
): ProtectedResponse<any> => {
  return await postProtected("profile/verify-email", values)
}

export const getIdentityVerificationToken =
  async (): ProtectedResponse<any> => {
    const res = await postProtected("profile/identity-verification")
    return res.data
  }

export const getDashboardAccounts = async (): ProtectedResponse<any> => {
  const res = await getProtected("dashboard-accounts")
  return res.data
}

export const getNotifications = async (params: {
  is_global: boolean
  account_id?: string
}): ProtectedResponse<any> => {
  const res = await getProtected("notifications", params)
  return res.data
}

export const viewNotification = (id: string): ProtectedResponse<any> => {
  assert(id)
  return putProtected(`notifications/${e(id)}`)
}

export const deleteNotification = <TVariables>(
  id: TVariables
): ProtectedResponse<any> => {
  assert(id)
  return deleteProtected(`notifications/${e(id as string)}`)
}

export const getAccountProperty = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/property`)
  return res.data
}

export const updateAccountProperty = (
  accountId: string,
  values?: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  return putProtected(`accounts/${e(accountId)}/property`, values)
}

export const requestAccountPropertyPreview = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await postProtected(
    `accounts/${e(accountId)}/property/preview`,
    values
  )
  return res.data
}

export const getAccountAccountCycles = async (
  accountId: string,
  params?: { is_archived: boolean }
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/cycles`, params)
  return res.data
}

export const getAccount = async (accountId: string): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}`)
  return res.data
}

export const createAccount = async (
  values: FormikValues
): ProtectedResponse<any> => {
  const res = await postProtected(`accounts`, values)
  return res.data
}

export const updateAccount = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await putProtected(`accounts/${e(accountId)}`, values)
  return res.data
}

// DEV: We call this `request` not `get` due to using a `PUT` call (required due to possibly creating payee in Tipalti)
export const requestAccountPaymentMethod = async (
  accountId: string,
  values?: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await putProtected(
    `accounts/${e(accountId)}/payment-method`,
    values
  )
  return res.data
}

export const getAccountUsers = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/users`)
  return res.data
}

export const addAccountUser = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await postProtected(`accounts/${e(accountId)}/users`, values)
  return res.data
}

export const updateAccountUser = async (
  accountId: string,
  email: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(email)
  const res = await putProtected(
    `accounts/${e(accountId)}/users/${e(email)}`,
    values
  )
  return res.data
}

export const removeAccountUser = async (
  accountId: string,
  email: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(email)
  const res = await deleteProtected(
    `accounts/${e(accountId)}/users/${e(email)}`
  )
  return res.data
}

export const getAccountAccessInfo = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/access-info`)
  return res.data
}

export const updateAccountAccessInfo = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(values)
  const res = await putProtected(`accounts/${e(accountId)}/access-info`, values)
  return res.data
}

export const getAccountOwnershipVerification = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(
    `accounts/${e(accountId)}/ownership-verification`
  )
  return res.data
}

export const getAccountInterest = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/interest`)
  return res.data
}

export const updateAccountInterest = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(values)
  const res = await postProtected(`accounts/${e(accountId)}/interest`, values)
  return res.data
}

export const getUserPosition = async (): ProtectedResponse<any> => {
  // Show most of USA at zoom level 4
  // DEV: Easiest way to look up: Use React Dev Tools and look at `props`
  let data = {
    default: true,
    latitude: 39.797640058138626,
    longitude: -98.15203600681883,
  }
  try {
    const res = await Axios.get("https://api.ipbase.com/v1/json/")
    data = res.data
  } catch (err: any) {
    // If our error isn't a network error (e.g. AdBlocker), then throw as per normal
    // DEV: "Network Error" is hardcoded by `axios` so we're good to hardcode it here, https://github.com/axios/axios/blob/v0.21.1/lib/adapters/xhr.js#L82-L84
    if (!(err.isAxiosError && err.message === "Network Error")) {
      throw err
    }
  }
  return data
}

export const getAddressLatLng = async (
  address: string
): Promise<{ lat: number; lng: number }> => {
  const results = await geocodeByAddress(address)
  return await getLatLng(results[0]) // {lat, lng}
}

interface ParcelReturnData {
  geometry: string // Geometry in WKT format (may later be converted)
  parcel_id: string
}

// ids is an array of ReportAll robust_id
export const getParcelData = async (
  ids: string[]
): Promise<ParcelReturnData[]> => {
  // Use session cache to get previously-requested parcels, where they exist
  const idsInCache: string[] = []
  const idsToRequest: string[] = []
  ids.forEach(function (id) {
    if (Object.keys(useSessionStore.getState().parcelCache).includes(id)) {
      idsInCache.push(id)
    } else {
      idsToRequest.push(id)
    }
  })

  // Get parcels from cache
  const results: { [key: string]: Record<string, string> } = {}
  idsInCache.forEach(
    (id) => (results[id] = useSessionStore.getState().parcelCache[id])
  )

  // If there are parcels we need that aren't cached...
  if (idsToRequest.length > 0) {
    // Assertion - getParcelData supports requesting multiple parcel IDs at a time but in practice
    // we expect to only request one. Don't break UX, but notify Sentry if we ever request with more
    // than one.
    if (idsToRequest.length > 5) {
      Sentry.captureMessage(
        "Requested " +
          idsToRequest.length +
          " parcels from ReportAll (expected only 1)"
      )
    }

    // Add uncached parcels to results and update cache
    const res = await Axios.get(
      `https://reportallusa.com/api/parcels.php?` +
        `client=${import.meta.env.VITE_APP_REPORTALL_CLIENT_KEY}` +
        `&v=${REPORT_ALL_API_VERSION}` +
        `&robust_id=${idsToRequest.join(";")}` +
        `&rpp=${idsToRequest.length}`
    )
    res.data.results.forEach((parcel: any) => {
      results[parcel.robust_id] = parcel
      useSessionStore.getState().addParcelToCache(parcel)
    })
  }

  // DEV: For assessment map we need to also pull in the parcel_id values
  // return ids.map((id) => results[id].geom_as_wkt)
  const parcelReturnData: ParcelReturnData[] = ids.map((id) => {
    return {
      geometry: results[id].geom_as_wkt,
      parcel_id: results[id].parcel_id,
    }
  })
  return parcelReturnData
}

export const buildAccountDocumentActionURL = ({
  accountId,
  cycleKey,
  documentType,
  action,
  returnUrl,
}: UseAccountDocumentActionURLTypes): string => {
  assertAccountId(accountId)
  assert(cycleKey && documentType && action)
  const queryParams: QueryParams = {}
  if (returnUrl) {
    queryParams.return_url = returnUrl
  }
  return buildSignedURL(
    `accounts/${e(accountId)}/cycles/${e(cycleKey)}/documents/${e(
      documentType
    )}/${e(action)}`,
    queryParams
  )
}

export const getProjectList = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/projects`)
  return res.data
}

export const getAttestations = async (
  accountId: string,
  projectId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(
    `accounts/${e(accountId)}/projects/${e(projectId)}/attestations`
  )
  return res.data
}

export const updateAttestations = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(values)
  const res = await postProtected(
    `accounts/${e(accountId)}/attestations`,
    values
  )
  return res.data
}

export const getProjectTypes = async (): ProtectedResponse<any> => {
  const res = await getProtected("projects/project-types")
  return res.data
}

export const updateAccountProject = async (
  accountId: string,
  projectId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(values)
  const res = await postProtected(
    `accounts/${e(accountId)}/projects/${e(projectId)}/accountproject`,
    values
  )
  return res.data
}

export const inviteYourNeighbor = async (
  values: FormikValues
): ProtectedResponse<any> => {
  assert(values)
  const res = await postProtected("refer-user/", values)
  return res.data
}

window.__errorTest = {
  server: async () => {
    return await getProtected(errorTestEndpoint)
  },
  browserSync: () => {
    throw new Error("Test error")
  },
  browserAsyncCallback: () => {
    setTimeout(() => {
      throw new Error("Async callback test error")
    }, 100)
  },
  browserAsyncPromise: () => {
    new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error("Async promise test error"))
      }, 100)
    })
  },
}

export const getQuestions = async (
  projectId: string
): ProtectedResponse<any> => {
  const res = await getProtected(`projects/${Number(projectId)}/questions`)
  return res.data
}

export const postQuestion = async (
  projectId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assert(values)
  const res = await postProtected(`projects/${e(projectId)}/questions`, values)
  return res.data
}

export const markAnswerHelpful = async (
  projectId: string,
  questionId: string,
  answerId: string
): ProtectedResponse<any> => {
  const res = await postProtected(
    `projects/${e(projectId)}/questions/${e(questionId)}/answers/${e(
      answerId
    )}/helpful`
  )
  return res.data
}

export const unmarkAnswerHelpful = async (
  projectId: string,
  questionId: string,
  answerId: string
): ProtectedResponse<any> => {
  const res = await deleteProtected(
    `projects/${e(projectId)}/questions/${e(questionId)}/answers/${e(
      answerId
    )}/helpful`
  )
  return res.data
}

export const getRoi = async (
  accountId: string,
  projectId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(
    `accounts/${e(accountId)}/projects/${e(projectId)}/roi`
  )
  return res.data
}

export const getRoiWithParams = async (
  accountId: string,
  projectId: string,
  queryParams: QueryParams
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const queryString =
    Object.keys(queryParams).length > 0
      ? new URLSearchParams(queryParams).toString()
      : undefined

  const res = await getProtected(
    queryString !== undefined
      ? `accounts/${e(accountId)}/projects/${e(projectId)}/roi?${queryString}`
      : `accounts/${e(accountId)}/projects/${e(projectId)}/roi`
  )

  return res.data
}

export const getStackables = async (
  accountId: string,
  projectId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(
    `accounts/${e(accountId)}/projects/${e(projectId)}/stackables`
  )
  return res.data
}

export const getTileLinks = async (
  accountId: string,
  projectId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`ncapi/${e(accountId)}/tile/links`, {
    project_id: projectId,
  })
  return res.data
}

export const getParcelAssessment = async (
  accountId: string,
  projectId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`ncapi/${e(accountId)}/parcel/assessment`, {
    project_id: projectId,
  })
  return res.data
}

export const getParcelGeoms = async (
  accountId: string
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`ncapi/${e(accountId)}/parcel/geoms`)
  return res.data
}

export const getAttestationQuiz = async (
  accountId: string,
  quizCategory?: EligibilityQuizCategoryType,
  sendAll?: boolean
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  const res = await getProtected(`accounts/${e(accountId)}/attestationquiz`, {
    quiz_category: quizCategory,
    send_all: sendAll,
  })
  return res.data
}

export const createStripeSession = async (
  accountId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  assertAccountId(accountId)
  assert(values)

  const res = await postProtected(
    `accounts/${e(accountId)}/create-checkout-session`,
    values
  )
  return res.data
}

// Partners

export const getPartnersDashboard = async (): ProtectedResponse<any> => {
  const res = await getProtected("partners")
  return res.data
}

export const getPartnersLandowner = async (
  apId: string
): ProtectedResponse<any> => {
  const res = await getProtected(`partners/ap/${e(apId)}`)
  return res.data
}

export const getPartnersInterestedProjects = async (
  apId: string
): ProtectedResponse<any> => {
  const res = await getProtected(`partners/ap/${e(apId)}/interested_projects`)
  return res.data
}

export const partnersBulkUpdateLandownerStatus = async (
  values: FormikValues
): ProtectedResponse<any> => {
  assert(values.status)
  assert(values.aps)
  const res = await postProtected(`partners/ap/bulk`, values)
  return res.data
}

export const getPartnersLandownerGeodownload = async (
  apId: string
): ProtectedResponse<any> => {
  const res = await getProtected(`partners/ap/${e(apId)}/geodownload`)
  return res.data
}

export const getPartnerAssignees = async (): ProtectedResponse<any> => {
  const res = await getProtected("partners/partner_assignees")
  return res.data
}

export const updatePartnerAssignees = async (
  apId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  const res = await postProtected(
    `partners/ap/${e(apId)}/partner_assignee`,
    values
  )
  return res.data
}

export const deletePartnerAssignees = async (
  apId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  const res = await deleteProtected(
    `partners/ap/${e(apId)}/partner_assignee`,
    values
  )
  return res.data
}

export const getPartnerTableRowTileLinks = async (
  apId: string
): ProtectedResponse<any> => {
  assertAccountId(apId)
  const res = await getProtected(`partners/ncapi/tile/links/ap/${e(apId)}`)
  return res.data
}

export const getPartnerTableRowParcelAssessment = async (
  apId: string
): ProtectedResponse<any> => {
  assertAccountId(apId)
  const res = await getProtected(
    `partners/ncapi/parcel/assessment/ap/${e(apId)}`
  )
  return res.data
}

export const getProjectReachoutDashboardView = async (
  sorting: SortingState,
  columnFilters: ColumnFiltersState,
  filterFunctions: EligibleLeadFilterFunctionsTypes
): ProtectedResponse<any> => {
  let endpoint = "partners/eligible_leads?page=1"

  if (!Array.isArray(columnFilters)) {
    return endpoint
  }

  const queryParams = columnFilters.map((param: ColumnFilter) => {
    const filterFn =
      filterFunctions[param.id as keyof EligibleLeadFilterFunctionsTypes]

    const key = param.id === "project_name" ? "program" : param.id
    const prefix =
      URL_PARAM_SUFFIX[filterFn as keyof typeof URL_PARAM_SUFFIX] || ""
    const hasValue = prefix !== "_isnull" && prefix !== "_exists"

    return `&${encodeURIComponent(key)}${prefix}${
      hasValue ? `=${encodeURIComponent(param.value as string)}` : ""
    }`
  })

  endpoint += `${queryParams.join("")}`

  if (sorting.length !== 0) {
    endpoint += `&order_by=${sorting[0].desc ? "-" : ""}${
      sorting[0].id === "project_name" ? "program" : sorting[0].id
    }`
  }

  const res = await getProtected(endpoint)

  return res.data
}

export const updateAccountProjectReachout = async (
  apId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  const res = await postProtected(
    `partners/eligible_leads/ap/${e(apId)}`,
    values
  )
  return res.data
}

export const unlockDisqualifyAccountProjectReachout = async (
  apId: string,
  values: FormikValues
): ProtectedResponse<any> => {
  const res = await putProtected(
    `partners/eligible_leads/ap/${e(apId)}`,
    values
  )
  return res.data
}

export const getPartnersReachoutsCounter = async (): ProtectedResponse<any> => {
  const res = await getProtected("partners/reachout_counter")
  return res.data
}

export const getProjectTablePageData = async (
  urlString: string
): ProtectedResponse<any> => {
  const url = new URL(urlString)
  const path = url.pathname.replace("/ncapx_platform/", "")
  const params = new URLSearchParams(url.search)
  const newParams = new URLSearchParams({
    ...(params.has("page") ? {} : { page: "1" }),
    ...Object.fromEntries(params.entries()),
  })

  const res = await getProtected(`${path}?${newParams.toString()}`)

  return res.data
}

export const requestTeaserAssessment = async (
  values: FormikValues
): Promise<any> => {
  const teaserAssessmentURL = ncapxPlatformURL + "ncapi/quick_assess"
  const res = await wrappedAxios("post", teaserAssessmentURL, values, {
    withCredentials: true,
  })
  return res.data
}

export const createAsanaTask = async (
  taskParams: AsanaTaskCreationParams
): Promise<any> => {
  assert(taskParams?.taskName)
  assert(taskParams?.projects)
  const {
    taskName,
    projects,
    description,
    useHtmlDescription = false,
  } = taskParams
  const notesField = useHtmlDescription ? "html_notes" : "notes"
  return await postProtected("create-task", {
    name: taskName,
    projects,
    task_params: {
      [notesField]: description,
    },
  })
}
